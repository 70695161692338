<template>
  <div :style="style">
    <div :class="$style.wrap">
      <div :class="$style.slider" @scroll="onScroll" ref="slider">
        <div :class="[$style.content, classContent]">
          <div
            :class="$style.slide"
            v-for="(_items, i) in slides"
            :key="i"
            :style="slideStyle(i)"
            ref="slide"
          >
            <slot
              :items="_items"
              :index="i"
              :is-active="i === currentPageIndex"
            />
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.pagination" v-if="pagination && pageCount > 1">
      <ui-arrow @click="prev" :disabled="progress === 0" />
      <ui-slider-dots
        v-if="paginationType === 'dots'"
        :count="pageCount"
        :value="currentPageIndex"
        @click="goToPageIndex($event)"
      />
      <div v-else-if="paginationType === 'numbers'" :class="$style.numbers">
        <span>{{ currentPageIndex + 1 }}</span>
        <span>/</span>
        <span>{{ pageCount }}</span>
      </div>
      <ui-arrow direction="right" @click="next" :disabled="progress === 1" />
    </div>
  </div>
</template>

<script>
import UiArrow from '~/components/ui/arrow'
import UiSliderDots from '~/components/ui/slider/dots'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

export default {
  name: 'UiSlider',
  components: {
    UiSliderDots,
    UiArrow
  },
  props: {
    perPage: {
      type: Number,
      default: 1
    },
    itemsPerSlide: {
      type: Number,
      default: 1
    },
    gap: {
      type: Number,
      default: 0
    },
    align: {
      type: String,
      default: 'start',
      validator: value => ['start', 'end', 'center'].includes(value)
    },
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Boolean,
      default: true
    },
    paginationType: {
      type: String,
      default: 'dots',
      validator: v => ['dots', 'numbers'].includes(v)
    },
    classContent: {
      type: String,
      default: ''
    },
    useScrollLock: {
      type: Boolean,
      default: false
    }
  },
  emits: ['progress', 'update'],
  data() {
    return {
      currentPageIndex: 0,
      progress: 0,
      slideWidth: 0,
      gapInPx: 0
    }
  },
  computed: {
    style() {
      return {
        '--half-slide-gap': `${this.gap / 2}em`,
        '--slide-align': this.align
      }
    },
    pageCount() {
      return Math.ceil(this.slides.length / Math.floor(this.perPage))
    },
    percentPerSlide() {
      return 100 / this.perPage
    },
    isFirst() {
      return !this.currentPageIndex
    },
    isLast() {
      return this.currentPageIndex + 1 === this.pageCount
    },
    slides() {
      const pageCount = Math.ceil(this.items.length / this.itemsPerSlide)
      return this.items.reduce(
        (columns, item, i) => {
          const page = Math.floor(i / this.itemsPerSlide)
          columns[page].push(item)
          return columns
        },
        Array.from({ length: pageCount }, () => [])
      )
    }
  },
  methods: {
    next() {
      this.goToPageIndex(
        Math.min(this.currentPageIndex + 1, this.pageCount - 1)
      )
    },
    prev() {
      this.goToPageIndex(Math.max(this.currentPageIndex - 1, 0))
    },
    goToPageIndex(index) {
      this.$refs.slider.scrollLeft =
        index * this.slideWidth * Math.floor(this.perPage)
    },
    goToSlideIndex(index) {
      const pageIndex = Math.floor(index / Math.floor(this.perPage))
      this.goToPageIndex(pageIndex)
    },
    goToItemIndex(index) {
      this.goToPageIndex(Math.floor(index / this.itemsPerSlide))
    },
    onScroll(e) {
      this.currentPageIndex =
        Math.ceil(this.progress * 100) === 100
          ? this.pageCount - 1
          : Math.round(
              e.target.scrollLeft / (this.slideWidth * Math.floor(this.perPage))
            )
      this.updateProgress()
    },
    slideStyle() {
      return {
        flex: `0 0 ${this.percentPerSlide}%`
      }
    },
    updateProgress() {
      this.progress = this.calcProgress()
      this.$emit('progress', this.progress)
    },
    update() {
      const currentPage = this.currentPageIndex + 1
      this.$emit('update', {
        currentPage,
        pageCount: this.pageCount,
        isFirst: this.isFirst,
        isLast: this.isLast,
        progress: this.progress
      })
    },
    calcProgress() {
      if (this.$refs.slider) {
        const scrollLeft = this.$refs.slider.scrollLeft
          ? Math.ceil(this.$refs.slider.scrollLeft + 1)
          : 0
        const maxScrollLeft = Math.ceil(
          this.$refs.slider.scrollWidth - this.$refs.slider.clientWidth
        )
        return Math.min(scrollLeft / maxScrollLeft, 1)
      }
      return 0
    },
    setGapInPX() {
      this.gapInPx = parseFloat(getComputedStyle(this.$refs.slider).fontSize)
    },
    setSlideWidth() {
      if (this.$refs.slide?.[0]) {
        this.slideWidth = this.$refs.slide[0].clientWidth
      }
    },
    onResize() {
      this.setSlideWidth()
      this.setGapInPX()
    }
  },
  watch: {
    currentPageIndex() {
      this.update()
    },
    items() {
      this.currentPageIndex = 0
      this.update()
    },
    gap() {
      this.update()
    },
    perPage() {
      this.update()
    }
  },
  created() {
    this.updateProgress()
    this.update()
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    if (this.useScrollLock) {
      disablePageScroll(this.$refs.scroll)
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
    if (this.useScrollLock) {
      enablePageScroll(this.$refs.scroll)
    }
  }
}
</script>

<style lang="scss" module>
.slider {
  @include hide-scrollbar;
  overflow: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
.wrap {
  margin: 0 calc(-1 * var(--half-slide-gap));
}
.content {
  display: flex;
}
.slide {
  min-width: 0;
  padding: 0 var(--half-slide-gap);
  scroll-snap-align: var(--slide-align);
}
.pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 3;
  margin-top: 2em;
  position: relative;
  .numbers {
    margin: 0 2.4em;
    span {
      font-weight: 400;
      font-size: 1.6em;
      line-height: 150%;
    }
  }
}
</style>
