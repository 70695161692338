<template>
  <button :class="classes" @click="onClick">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.01322 7.43431C3.7008 7.74673 3.7008 8.25327 4.01322 8.56569L9.10439 13.6569C9.41681 13.9693 9.92334 13.9693 10.2358 13.6569C10.5482 13.3444 10.5482 12.8379 10.2358 12.5255L5.71028 8L10.2358 3.47452C10.5482 3.1621 10.5482 2.65557 10.2358 2.34315C9.92334 2.03073 9.41681 2.03073 9.10439 2.34315L4.01322 7.43431ZM5.37891 7.2L4.57891 7.2L4.57891 8.8L5.37891 8.8L5.37891 7.2Z"
        fill="currentColor"
      />
    </svg>
  </button>
</template>

<script lang="ts" setup>
import { useCssModule, computed } from 'vue'
import type { Direction, Size } from './consts'

defineOptions({
  name: 'UiArrow'
})

const props = withDefaults(
  defineProps<{
    direction?: Direction
    size?: Size
    disabled?: boolean
  }>(),
  {
    direction: 'left',
    size: 'medium',
    disabled: false
  }
)

const emit = defineEmits<{
  click: [event: MouseEvent]
}>()

const cssModule = useCssModule()

const classes = computed(() => [
  cssModule.arrow,
  cssModule[`dir-${props.direction}`],
  cssModule[`size-${props.size}`],
  props.disabled && cssModule.disabled
])

const onClick = (event: MouseEvent) => {
  if (!props.disabled) {
    emit('click', event)
  }
}
</script>

<style lang="scss" module>
.arrow {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;
  background: rgba(0, 199, 177, 0.1);
  color: #fff;
  flex-shrink: 0;
  &:hover {
    transform: scale(1.15);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dir-left {
}
.dir-right {
  svg {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

.size-medium {
  width: 4em;
  height: 4em;
  svg {
    width: 2em;
    height: 2em;
  }
}
</style>
